import React from "react";
import { useSelector } from "react-redux";
import { Route, useHistory } from "react-router-dom";
import { ROUTES } from "../../app/constants";
import { selectUid } from "../../features/auth/authSlice";

const ProtectedRouteWithLayout = ({
  component: Component,
  layout: Layout,
  ...rest
}) => {
  const uid = useSelector(selectUid);
  const history = useHistory();

  if (!uid) {
    history.push(ROUTES.LOGIN);
  }

  return (
    <Route
      {...rest}
      render={(props) => {
        return (
          <Layout>
            <Component {...props} {...rest} />
          </Layout>
        );
      }}
    />
  );
};

export default ProtectedRouteWithLayout;
