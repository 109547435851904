import { createSlice } from "@reduxjs/toolkit";

export const USER_DETAILS = "user-details";

const initialState = {
    userDetails: {
        languages: [],
        requests: [],
        email: "",
        fullname: "",
        city: "",
        status: ""
    }
}

export const userDetailsSlice = createSlice({
    name: USER_DETAILS,
    initialState,
    reducers: {
        setUserDetails: (state, { payload }) => {
            state.userDetails = payload;
        }
    }
})

export const selectUserDetails = (state) => state[USER_DETAILS].userDetails;

export const { setUserDetails } = userDetailsSlice.actions;

export default userDetailsSlice.reducer;