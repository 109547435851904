import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getUnseenMessages } from "../../services/globalAPI";
import { sortChats } from "../../utilities/helpers/arrayFilters";
import { selectUid } from "../auth/authSlice";
import { getMessagesList, newMessagesListener } from "../Messages/messagesAPI";
import { setChats } from "../Messages/messagesSlice";
import { setUnseenMessages } from "./layoutSlice";

const DatabaseListeners = ({ children }) => {
  const adminID = useSelector(selectUid);
  const dispatch = useDispatch();

  try {
    async function callBack(userData) {
      const { messages } = userData.data();

      const msgList = await getMessagesList(messages);
      const unseenCount = await getUnseenMessages();
      const sortedChats = sortChats(msgList);
      dispatch(setUnseenMessages(unseenCount));
      dispatch(setChats(sortedChats));
    }

    useEffect(() => {
      if (adminID) {
        newMessagesListener(adminID, callBack);
      }
      // eslint-disable-next-line
    }, []);
  } catch (error) {
    console.log("Messages listener error");
    console.log(error);
  }

  return <div>{children}</div>;
};

export default DatabaseListeners;
