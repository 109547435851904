import React from "react";
import { Grid, makeStyles } from "@material-ui/core";
// import LoadingLayout from "./LoadingLayout";


const useStyles = makeStyles((theme) => ({
    wrapper: {
        marginTop: 60,
        [theme.breakpoints.up("md")]: {
            paddingTop: 20
        }
    },
}));

export function UnauthorizedLayout({ children }) {
    const classes = useStyles();

    // const [loading, setLoading] = useState(false);

    // if (loading) {
    //     return <LoadingLayout />
    // }

    return (
        <>
            <Grid
                className={classes.wrapper}
            >
                {children}
            </Grid>
        </>
    );
}