import {
    RECORDING_BASE_PATH, ROLES
} from "../../app/constants"

export const filterConsultations = (arr, tabIndex) => {

    function onlyNew(cons) {
        const dateNow = new Date().getTime()
        const consTime = new Date(cons.data.date).getTime()
        if (dateNow <= consTime) {
            return cons
        }
    }

    function sortAsc(a, b) {
        return new Date(b.data.date).getTime() - new Date(a.data.date).getTime()
    }

    switch (tabIndex) {
        case 0:
            return arr.filter(onlyNew).sort(sortAsc)
        case 1:
            return arr.filter(onlyNew).sort(sortAsc)
        case 2:
            return arr.sort(sortAsc)
        default:
            return arr.sort(sortAsc)

    }
}

export const filterChats = (chats, keyword) => {
    if (!keyword.length) return chats;
    if (!chats.length) return [];

    const filterCB = (x) => {
        return x.userData.fullname.toLowerCase().includes(keyword.toLowerCase())
    }

    const result = chats.filter(filterCB);
    return result
}

export const sortChats = (chats) => {
    if (!chats.length) return [];

    const sorted = chats.sort((a, b) => { return b.lastMessage.date - a.lastMessage.date });

    return sorted;
}

export const getChatIndex = (chats = [], msgID = '') => {
    return chats.findIndex(x => x.messagesID === msgID)
}

export const setupRecords = (files = []) => {
    if (!files.length) return [];

    const matched = files.reduce((acc, cVal, i) => {

        const bound = [cVal, files[i + 1]];

        if (!(i % 2)) {
            acc.push(bound)
        }

        return acc;
    }, [])
    // eslint-disable-next-line
    const links = matched.map(([a, b]) => {
        if (a && b) {
            return [RECORDING_BASE_PATH + a.filename, RECORDING_BASE_PATH + b.filename]
        }
    }).filter(Boolean)

    return links;

}

export const filterChatsByRole = (chats, role) => {
    const currentRole = role === 0 ? ROLES.CLIENT : ROLES.CRAFTSMAN

    const forCurrentRole = chats.filter((({ userData }) => {
        return userData.role === currentRole
    }))

    return forCurrentRole;
}

export const getClientMessagesCount = (arr = []) => {

    const count = arr.filter((msg) => {
        return msg.isNew === true && msg.userData.role === ROLES.CLIENT
    }).length

    return count;
}


export const getCraftsmanMessagesCount = (arr = []) => {
    const count = arr.filter((msg) => {
        return msg.isNew === true && msg.userData.role === ROLES.CRAFTSMAN
    }).length

    return count;
}