import React, { useEffect, useState } from "react";
import { Grid, } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { useDispatch } from "react-redux";
import { getAuth, onAuthStateChanged } from "@firebase/auth";
import { useHistory } from "react-router";
import { authenticate, } from "../auth/authSlice";
import { checkUser, } from "../auth/authAPI";
import LoadingLayout from "./LoadingLayout";
import { ROUTES } from "../../app/constants";

const useStyles = makeStyles((theme) => ({
    wrapper: {
        marginTop: 90,
        marginBottom: 10,
        // paddingTop: 20
    }
}));

export function AuthorizedLayout({ children }) {
    const classes = useStyles();
    const history = useHistory();
    const [loading, setLoading] = useState(true)
    const dispatch = useDispatch();
    // eslint-disable-next-line

    useEffect(() => {
        setLoading(true);
        onAuthStateChanged(getAuth(), (user) => {
            if (user) {
                checkUser(user.uid)
                    .then((data) => {
                        if (data) {
                            dispatch(authenticate(data))
                        } else {
                            history.push(ROUTES.LOGOUT);
                        }
                        setLoading(false)
                    }).catch(console.log())
                // setUser("Logged as: " + user.email)
                setLoading(false)
            } else {
                // setUser("not logged")
                setLoading(false)
                // redirect to unauthorized page here !
            }
        })
        // eslint-disable-next-line
    }, [window.location.hash])

    if (loading) {
        return (
            <LoadingLayout />
        )
    }

    return (
        <div>
            <Grid className={classes.wrapper} container direction="column">
                {/* {user !== "not logged" && <Link to="/logout">Logout</Link>} */}
                {/* {user} */}
                {children}
            </Grid>
        </div>
    );
}
