import React from "react";
import { HashRouter } from "react-router-dom";
import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import { Router } from "./app/routes";
import { Provider } from "react-redux";
import { store, persistor } from "./app/store";
import theme from "./app/theme";
import './app/firebase';
import './assets/css/fonts.css'

import { PersistGate } from "redux-persist/integration/react";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import 'react-loading-skeleton/dist/skeleton.css';

import DateFnsUtils from "@date-io/date-fns";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import LoadingOverlay from "./features/layout/LoadingOverlay";
import MainLayout from "./features/layout/MainLayout";

const appTheme = createTheme(theme);

function App() {
  console.log("15.02.2022");
  return (
    <HashRouter>
      <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
          <ThemeProvider theme={appTheme}>
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
              <LoadingOverlay />
              <MainLayout>
                <Router />
              </MainLayout>
            </MuiPickersUtilsProvider>
          </ThemeProvider>
        </PersistGate>
      </Provider>
    </HashRouter>
  );
}

export default App;
