import { createSlice } from '@reduxjs/toolkit';
import { CRAFTSMAN_STATUS } from '../../app/constants';

export const CRAFTSMAN_LIST_PAGE_SLICE = 'craftsman-list-page';

const initialState = {
    pageView: null,
    category: null,
    requestsTab: CRAFTSMAN_STATUS.PENDING,
    tabIndex: 0,
    requests: [],
}

export const craftsmanListPageSlice = createSlice({
    name: CRAFTSMAN_LIST_PAGE_SLICE,
    initialState,
    reducers: {
        setPageView: (state, { payload }) => {
            state.pageView = payload;
        },
        setCategory: (state, { payload }) => {
            state.category = payload;
        },
        setRequestsTab: (state, { payload }) => {
            state.requestsTab = payload;
        },
        setTabIndex: (state, { payload }) => {
            state.tabIndex = payload;
        },
        setRequests: (state, { payload }) => {
            const temp = [...payload];
            state.requests = temp;
        },
        extendRequests: (state, { payload }) => {
            const bounded = [...state.requests, ...payload]
            state.requests = bounded
        }
    }
});

export const selectPageView = (state) => state[CRAFTSMAN_LIST_PAGE_SLICE].pageView;

export const selectExpertCategoryName = (state) => state[CRAFTSMAN_LIST_PAGE_SLICE].category;

export const selectRequestsTab = (state) => state[CRAFTSMAN_LIST_PAGE_SLICE].requestsTab;

export const selectTabIndex = (state) => state[CRAFTSMAN_LIST_PAGE_SLICE].tabIndex;

export const selectRequests = (state) => state[CRAFTSMAN_LIST_PAGE_SLICE].requests;

export const {
    setPageView,
    setCategory,
    setRequestsTab,
    setTabIndex,
    setRequests,
    extendRequests
} = craftsmanListPageSlice.actions;

export default craftsmanListPageSlice.reducer;