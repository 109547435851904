import { createSlice } from '@reduxjs/toolkit';
import { REQUESTS_TABS_KEY_WORDS } from '../../app/constants';

export const CRAFTSMAN_HOME_PAGE = "craftsman-home-page";

const initialState = {
    tabIndex: 0,
    requestsKeyWords: REQUESTS_TABS_KEY_WORDS.REQUESTED,
    requests: []
}

export const craftsmanHomePageSlice = createSlice({
    name: CRAFTSMAN_HOME_PAGE,
    initialState,
    reducers: {
        setTab: (state, { payload }) => {
            state.tabIndex = payload;
        },
        setRequestsKeyWords: (state, { payload }) => {
            const temp = [...payload]
            state.requestsKeyWords = temp;
        },
        setRequests: (state, { payload }) => {
            const temp = [...payload];
            state.requests = temp;
        },
        extendRequests: (state, { payload }) => {

            const bounded = [...state.requests, ...payload]

            state.requests = bounded;
        }
    }
});

export const selectTabIndex = (state) => state[CRAFTSMAN_HOME_PAGE].tabIndex;

export const selectRequestsKeyWords = (state) => state[CRAFTSMAN_HOME_PAGE].requestsKeyWords;

export const selectRequests = (state) => state[CRAFTSMAN_HOME_PAGE].requests;

export const { setTab, setRequestsKeyWords, setRequests, extendRequests } = craftsmanHomePageSlice.actions;

export default craftsmanHomePageSlice.reducer;
