import { createSlice } from "@reduxjs/toolkit";

export const LOADING_REDUCER = 'loading';

const initialState = {
    loading: false,
};

export const loadingSlice = createSlice({
    name: LOADING_REDUCER,
    initialState,
    reducers: {
        setLoading(state, { payload }) {
            state.loading = payload
        },

    },
});

export const selectLoading = (state) => state[LOADING_REDUCER].loading;

export const {
    setLoading,
} = loadingSlice.actions;

export default loadingSlice.reducer