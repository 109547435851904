import { getDocs, getFirestore, query, collection, where, getDoc, doc, } from "firebase/firestore";
import { getAuth } from "@firebase/auth";
import { TODAY } from "../app/constants";

export const getUnseenMessages = async () => {
    const firestore = getFirestore();

    const uid = await getAuth().currentUser.uid;
    const user = await (await getDoc(doc(firestore, "admin", uid))).data();

    const { messages } = user;
    if (!messages.length) return 0;

    const unseenCount = messages.filter(x => x.includes("@")).length

    return unseenCount;
}

export const getPendingCraftsmanOnly = async () => {
    try {

        const firestore = getFirestore();

        const craftsmanRef = collection(firestore, "craftsman");

        const q = query(craftsmanRef, where("status", "==", "PENDING"))

        const count = await (await getDocs(q)).size;

        return count || 0;
    } catch (error) {
        console.log(error)
    }

}

export const getNewConsultations = async () => {
    const firestore = getFirestore();
    const consultationsRef = collection(firestore, "request");

    const q = query(consultationsRef, where("state", "==", "new"), where("data.date", ">=", TODAY),
    )
    const count = await (await getDocs(q)).size;
    return count;
}