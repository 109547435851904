import React from "react";
import FadeLoader from "react-spinners/FadeLoader";
import { makeStyles } from "@material-ui/core";
import TopAppBarDesktop from "../../components/common/TopAppBarDesktop";

const useStyles = makeStyles((theme) => ({
  root: {
    position: "absolute",
    top: "30vh",
    width: "100%",
    display: "flex",
    justifyContent: "center",
    [theme.breakpoints.up("md")]: {
      left: "-0.1%"
    }
  },
}));

const LoadingLayout = () => {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <TopAppBarDesktop />
      <FadeLoader loading={true} color="#F28900" />
    </div>
  );
};

export default LoadingLayout;