const theme = {
    themeColors: {
        green: "#2EA715",
        darkBlue: "#042C5C",
    },
    palette: {
        primary: {
            main: "#F28900",
            contrastText: "#fff"
        },
        secondary: {
            main: "#DC0808",
        },
        error: {
            main: "#C62230",
        },
        text: {
            primary: "#4A545D",
            secondary: "#4A545D",
            disabled: "#BFBFBF",
        },
    },
    overrides: {
        MuiInputBase: {
            root: {
                "&$disabled": {
                    color: "#000000"
                },
            },
        },
        MuiInput: {
            root: {
                fontWeight: 500,
                fontSize: 14,
                paddingTop: 4,
                paddingBottom: 4,

                "&&&:before": {
                    borderBottom: "none"
                },
                "&&:after": {
                    borderBottom: "none"
                },
                "&hover": {
                    textDecoration: "none"
                }
            },
        },
        MuiSelect: {
            select: {
                "&:focus": {
                    backgroundColor: "none"
                }
            }
        },
        MuiOutlinedInput: {
            root: {
                height: "32px",
                color: "#707070",
                backgroundColor: "#fff",

                "&:hover $notchedOutline": {
                    borderColor: "#4A545D",
                },
                "& fieldset": {
                    border: "none",
                    boxShadow: '1px 0px 5px 1px lightGray'
                },
                borderRadius: 4,
                fontWeight: 500,

            },
            focused: {},
            notchedOutline: {},
        },
        MuiButton: {
            root: {
                borderRadius: 20,
                height: 40,
                minWidth: 60,
                maxWidth: 280,
                fontWeight: 600,
                "&$disabled": {
                    color: "#fff",
                    backgroundColor: "#8A8A8A"
                },
                "&$outlined": {
                    borderRadius: 10,
                    backgroundColor: "#fff"
                }
            },
            textPrimary: {
                fontSize: 14,
                fontFamily: "sans-serif",
                textTransform: 'capitalize',
                borderRadius: 0
            },
        },
        MuiSlider: {
            rail: {
                marginTop: 2
            },
            track: {
                marginTop: 2
            },
            thumb: {
                height: 16,
                width: 16
            },
            valueLabel: {
                left: 'calc(-50% )',
                top: -22,
                '& *': {
                    background: 'transparent',
                    color: "#4A545D"
                },
            },
            mark: {
                color: "#fff",
            },
            markLabel: {
                fontSize: 14
            }
        },
        MuiRating: {
            root: {
                fontSize: 16,
                color: "#F28900"
            },
        },
        MuiTabs: {
            root: {
                backgroundColor: "#fff",
                borderRadius: 5,
                padding: 0,
                minHeight: 30
            },
            scroller: {
                backgroundColor: "#8A8A8A",
            },

            indicator: {
                display: "none",
            },
        },
        MuiTab: {
            root: {
                fontSize: 14,
                height: 28,
                minHeight: 28,
                textTransform: "capitalize",
                color: "#fff",
                margin: 2,
                "&$selected": {
                    backgroundColor: "#fff",
                    borderRadius: 5,
                }
            },

            textColorPrimary: {
                color: "#fff"
            }
        },
        MuiAvatar: {
            root: {

            },
            fallback: {

            },

        },
        MuiChip: {
            root: {

            },
            outlined: {
                borderRadius: 12,
            },
            outlinedPrimary: {
                color: "#000",
                fontSize: 17,
                padding: 6,
                minHeight: 35
            }
        },
        MuiAccordion: {
            root: {
                background: "transparent",
                boxShadow: "none"
            }
        }
    },

    typography: {
        color: "#000000",
        fontFamily: "sans-serif",
        fontSize: 16,
        body2: {
            fontSize: 18,
            fontWeight: 700
        },
        subtitle1: {
            fontSize: 14,
            color: "#707070"
        },
        subtitle2: {
            fontSize: 12,
            color: "#707070",
            fontWeight: 400
        },
    },
}

export default theme;
