import { createSlice } from "@reduxjs/toolkit";
import { composeMessages } from "../../utilities/helpers/composeMessage";

export const CHAT_SLICE = 'chat';

const initialState = {
    chats: [],
    currentChatIndex: null,
    currentUserID: null,
    currentMessagesID: null,
    chatAvatar: "",
    fullName: "",
    currentMessages: [],
    chatLoading: true,
    messageText: "",
    imagesToSend: [],
    searchResults: [],
    keyword: "",
    userTypeIndex: 0,
    clientMessages: 0,
    craftsmanMessages: 0
}

export const chatSlice = createSlice({
    name: CHAT_SLICE,
    initialState,
    reducers: {
        setChats: (state, { payload }) => {
            state.chats = [...payload]
        },
        setCurrentChatIndex: (state, { payload }) => {
            state.currentChatIndex = payload;
        },
        setCurrentUserID: (state, { payload }) => {
            state.currentUserID = payload;
        },
        setCurrentMessagesID: (state, { payload }) => {
            state.currentMessagesID = payload;
        },
        setChatAvatar: (state, { payload }) => {
            state.chatAvatar = payload;
        },
        setFullName: (state, { payload }) => {
            state.fullName = payload;
        },
        setCurrentMessages: (state, { payload }) => {
            if (payload) {
                const isEmpty = Object.keys(payload).length === 0;
                if (!isEmpty) {
                    const formatted = composeMessages(Object.values(payload))
                    state.currentMessages = formatted;
                }
            } else {
                state.currentMessages = []
            }
        },
        setChatLoading: (state, { payload }) => {
            state.chatLoading = payload;
        },
        setMessageText: (state, { payload }) => {
            state.messageText = payload;
        },
        setImagesToSend: (state, { payload }) => {
            state.imagesToSend = [...payload];
        },
        setKeyword: (state, { payload }) => {
            state.keyword = payload.trim();
        },
        setMessageSeen: (state, { payload }) => {
            const temp = [...state.chats]
            const indexToUpdate = temp.findIndex(x => x.messagesID === payload);
            temp[indexToUpdate].isNew = false;

            state.chats = temp;

        },
        setUserTypeIndex: (state, { payload }) => {
            state.userTypeIndex = payload;
        },
        setCraftsmanMessages: (state, { payload }) => {
            state.craftsmanMessages = payload;
        },
        setClientMessages: (state, { payload }) => {
            state.clientMessages = payload;
        }

    }
})

export const selectChats = (state) => state[CHAT_SLICE].chats;

export const selectCurrentChatIndex = (state) => state[CHAT_SLICE].currentChatIndex;

export const selectCurrentUserID = (state) => state[CHAT_SLICE].currentUserID;

export const selectCurrentMessagesID = (state) => state[CHAT_SLICE].currentMessagesID;

export const selectChatAvatar = (state) => state[CHAT_SLICE].chatAvatar;

export const selectFullName = (state) => state[CHAT_SLICE].fullName;

export const selectCurrentMessages = (state) => state[CHAT_SLICE].currentMessages;

export const selectChatLoading = (state) => state[CHAT_SLICE].chatLoading;

export const selectMessageText = (state) => state[CHAT_SLICE].messageText;

export const selectImagesToSend = (state) => state[CHAT_SLICE].imagesToSend;

export const selectKeyword = (state) => state[CHAT_SLICE].keyword;

export const selectUserTypeIndex = (state) => state[CHAT_SLICE].userTypeIndex;

export const selectClientMessages = (state) => state[CHAT_SLICE].clientMessages;

export const selectCraftsmanMessages = (state) => state[CHAT_SLICE].craftsmanMessages;


export const {
    setChats,
    setCurrentChatIndex,
    setCurrentMessagesID,
    setCurrentUserID,
    setChatAvatar,
    setFullName,
    setCurrentMessages,
    setChatLoading,
    setMessageText,
    setImagesToSend,
    setKeyword,
    setMessageSeen,
    setUserTypeIndex,
    setClientMessages,
    setCraftsmanMessages
} = chatSlice.actions;

export default chatSlice.reducer;