import React, { useEffect } from 'react'
import { AppBar, Avatar, Grid, Typography, Toolbar, IconButton, Badge } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { useHistory } from 'react-router';
import { ReactComponent as ImageLogo } from '../../assets/img/logo.svg'
import { ReactComponent as MessagesIcon } from '../../assets/img/appBar/messages.svg'
import { ReactComponent as MessagesIconActive } from '../../assets/img/appBar/messagesActive.svg';
import { ReactComponent as SpecialistIcon } from '../../assets/img/appBar/specialist.svg';
import { ReactComponent as SpecialistIconActive } from '../../assets/img/appBar/specialistActive.svg';
import { ReactComponent as UserIcon } from '../../assets/img/appBar/user.svg';
import { ReactComponent as UserIconActive } from '../../assets/img/appBar/userActive.svg';
import { ReactComponent as ConsultationsIcon } from '../../assets/img/appBar/consultations.svg';
import { ReactComponent as ConsultationsIconActive } from '../../assets/img/appBar/consultationsActive.svg';

import { useDispatch, useSelector } from "react-redux";
import { APP_BAR_ELEMENTS, ROUTES } from '../../app/constants';
import {
    selectCurrentPage,
    // selectNewConsultations,
    selectUnseenMessages,
    setCurrentPage,
    // setNewConsultations,
    setCurrentActivePage,
    // selectCurrentActivePage 
} from '../../features/layout/layoutSlice';
import { selectEmail } from '../../features/auth/authSlice';
import { getPendingCraftsmanOnly } from '../../services/globalAPI';
import { selectPendingCraftsman, setPendingCraftsman } from '../../features/layout/layoutSlice';

const useStyles = makeStyles((theme) => ({
    root: {
        background: "#fff",
        color: "#9D9D9D",
        "& .MuiTypography-body1": {
            fontSize: 14,
            fontWeight: 600
        },
        "& .MuiIconButton-root": {
            paddingLeft: 0
        },
        "& .MuiBadge-anchorOriginTopRightRectangle": {
            top: -1,
        },
        "& .MuiBadge-badge": {
            right: -8
        }
    },
    navItem: {
        display: "flex",
        alignItems: "center",
        textAlign: "center",
        cursor: "pointer",
    },
    btn: {
        maxHeight: 35,
        width: 85,
        fontSize: 14
    },
    logoBox: {
        maxWidth: "35%"
    },
    iconSvg: {
        marginRight: "0.5rem"
    },
    avatarImg: {
        marginLeft: "0.7rem"
    },

}));

const TopAppBarDesktop = ({ profile }) => {
    const classes = useStyles();
    const history = useHistory();
    const email = useSelector(selectEmail)
    const dispatch = useDispatch();
    const selectedElement = useSelector(selectCurrentPage);
    const pendingCraftsman = useSelector(selectPendingCraftsman);
    const unseenMessages = useSelector(selectUnseenMessages);

    const handleSelect = (element, path) => () => {
        console.log(element);
        console.log(path);

        dispatch(setCurrentPage(element));
        dispatch(setCurrentActivePage(path))
        // redirect to page here todo

        history.push(path);
    };

    useEffect(() => {
        fetchData();
        // history.push(currentActivePage);
        // eslint-disable-next-line
    }, [])

    async function fetchData() {
        const pendingCont = await getPendingCraftsmanOnly();
        dispatch(setPendingCraftsman(pendingCont));
    }

    return (
        <AppBar elevation={0} className={classes.root}>
            <Toolbar>
                <Grid container direction="row" alignItems="center" alignContent="center" justifyContent="space-between">
                    <Grid className={classes.logoBox} md={2} lg={7} item>
                        <ImageLogo />
                    </Grid>

                    <Grid className={classes.navItem} item>
                        <IconButton onClick={handleSelect(APP_BAR_ELEMENTS.CONSULTATION, ROUTES.CONSULTATIONS_LIST)}>
                            {selectedElement === APP_BAR_ELEMENTS.CONSULTATION
                                ? <ConsultationsIconActive className={classes.iconSvg} />
                                : <ConsultationsIcon className={classes.iconSvg} />
                            }
                            {/* <Badge color="primary" badgeContent={onlyNewConsultations}> */}
                            <Typography  >Consultations</Typography>
                            {/* </Badge> */}
                        </IconButton>
                    </Grid>

                    <Grid className={classes.navItem} item>
                        <IconButton onClick={handleSelect(APP_BAR_ELEMENTS.EXPERT, ROUTES.CRAFTSMAN_LIST)}>
                            {selectedElement === APP_BAR_ELEMENTS.EXPERT
                                ? <SpecialistIconActive className={classes.iconSvg} />
                                : <SpecialistIcon className={classes.iconSvg} />
                            }
                            <Badge color="primary" badgeContent={pendingCraftsman}>
                                <Typography  >Experts</Typography>
                            </Badge>
                        </IconButton>
                    </Grid>

                    <Grid className={classes.navItem} item>
                        <IconButton onClick={handleSelect(APP_BAR_ELEMENTS.USERS, ROUTES.USERS_LISTING)} >
                            {selectedElement === APP_BAR_ELEMENTS.USERS
                                ? <UserIconActive className={classes.iconSvg} />
                                : <UserIcon className={classes.iconSvg} />
                            }
                            <Typography >Users</Typography>
                        </IconButton>
                    </Grid>
                    <Grid className={classes.navItem} item>
                        <IconButton onClick={handleSelect(APP_BAR_ELEMENTS.MESSAGES, ROUTES.MESSAGES)}>
                            {selectedElement === APP_BAR_ELEMENTS.MESSAGES
                                ? <MessagesIconActive className={classes.iconSvg} />
                                : <MessagesIcon className={classes.iconSvg} />
                            }
                            <Badge color="primary" badgeContent={unseenMessages}>
                                <Typography >Messages</Typography>
                                {/* color={selectedElement === APP_BAR_ELEMENTS.MESSAGES ? "primary" : "inherit"} */}
                            </Badge>
                        </IconButton>

                    </Grid>

                    <Grid xs={2} className={classes.navItem} item>
                        <IconButton >
                            <Typography color={selectedElement === APP_BAR_ELEMENTS.PROFILE ? "primary" : "inherit"} >{email}</Typography>
                            <Avatar className={classes.avatarImg} src={""} />
                        </IconButton>
                    </Grid>
                </Grid>
            </Toolbar>
        </AppBar>
    )
}

export default TopAppBarDesktop