import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import './app/firebase'
import { initFirebaseApp } from "./app/firebase";

import * as serviceWorker from "./serviceWorker";

initFirebaseApp(ReactDOM.render(<App />, document.getElementById("root")))

serviceWorker.unregister();
