import { configureStore } from '@reduxjs/toolkit';
import { combineReducers } from 'redux';
import storage from "redux-persist/lib/storage";

import {
  persistStore,
  persistReducer
} from "redux-persist";

import loadingReducer, { LOADING_REDUCER } from "../features/layout/loadingSlice";
import authReducer, { AUTH_SLICE } from '../features/auth/authSlice';
import craftsmanListPageReducer, { CRAFTSMAN_LIST_PAGE_SLICE } from "../features/CraftsmanList/craftsmanListPageSlice";
import craftsmanProfileReducer, { CRAFTSMAN_DETAILS } from '../features/CraftsmanProfile/craftsmanProfileSlice';
import userListPageReducer, { USER_LIST_PAGE_SLICE } from "../features/UserList/userListPageSlice";
import userProfileReducer, { USER_DETAILS } from '../features/UserProfile/userProfileSlice';
import craftsmanHomePageReducer, { CRAFTSMAN_HOME_PAGE } from "../features/ConsultationsList/ConsultationsListSlice";
import consultationDetailsReducer, { CONSULTATION_DETAILS } from "../features/ConsultationDetails/consultationDetailsSlice";

import layoutSlice, { LAYOUT_SLICE } from '../features/layout/layoutSlice';
import chatReducer, { CHAT_SLICE } from '../features/Messages/messagesSlice';

const persistConfig = {
  key: "admin-diy-225db350-73a7-11ec-90d6-0242ac120003",
  version: 1,
  storage,
  whitelist: [AUTH_SLICE, LAYOUT_SLICE],
  blacklist: [
    LOADING_REDUCER,
    CRAFTSMAN_DETAILS,
    CHAT_SLICE,
    LAYOUT_SLICE,
    USER_DETAILS,
    CRAFTSMAN_HOME_PAGE,
    CONSULTATION_DETAILS,
  ]
};

const rootReducer = combineReducers({
  [LOADING_REDUCER]: loadingReducer,
  [AUTH_SLICE]: authReducer,
  [CRAFTSMAN_LIST_PAGE_SLICE]: craftsmanListPageReducer,
  [CRAFTSMAN_DETAILS]: craftsmanProfileReducer,
  [LAYOUT_SLICE]: layoutSlice,
  [CRAFTSMAN_HOME_PAGE]: craftsmanHomePageReducer,
  [CONSULTATION_DETAILS]: consultationDetailsReducer,
  [CHAT_SLICE]: chatReducer,
  [USER_LIST_PAGE_SLICE]: userListPageReducer,
  [USER_DETAILS]: userProfileReducer
});

const persistedReducer = persistReducer(persistConfig, rootReducer);

export const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false
    }),
});

export const persistor = persistStore(store);
