import { differenceInDays, isToday } from "date-fns";
import { FORMAT_LAST_MESSAGE_DATE, MESSAGE_TYPES } from "../../app/constants";

export const composeMessages = (messagesArr) => {

    let lastDay = new Date(0);

    const formatted = messagesArr.reduce((acc, cVal) => {
        const { filePath, message, videoCallID } = cVal;
        let type = "";
        const messageDate = new Date(cVal.date);
        const daysDiff = Math.abs(differenceInDays(lastDay, messageDate))

        if (message.length) type = MESSAGE_TYPES.TEXT;
        if (filePath.length) type = MESSAGE_TYPES.FILE;
        if (cVal.imagePaths && cVal.imagePaths.length) type = MESSAGE_TYPES.PHOTOS;
        if (videoCallID.length) type = MESSAGE_TYPES.CALL;

        if (daysDiff >= 1) {
            lastDay = messageDate;

            if (isToday(messageDate)) {
                acc.push({
                    ...cVal, type: "divider",
                    text: "TODAY"
                })
            } else {
                acc.push({
                    ...cVal, type: "divider",
                    text: FORMAT_LAST_MESSAGE_DATE(messageDate)
                })
            }
        }

        if (type.length) {
            acc.push({ ...cVal, type })
        }

        return acc;

    }, []);

    return formatted;
}