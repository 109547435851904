import React from 'react'
import { makeStyles } from '@material-ui/core';
import FadeLoader from 'react-spinners/FadeLoader';
import { useSelector } from 'react-redux';
import { selectLoading } from './loadingSlice';

const useStyles = makeStyles((theme) => ({
    loader: {
        position: "absolute",
        height: "90vh",
        width: "100vw",
        backgroundColor: "transparent",
        boxShadow: "0px -2px 2px rgba(34,34,34,0.6)"
    },
    spinner: {
        height: 100,
        width: 100,
        marginTop: "40%",
        margin: "auto",
        display: "flex",
        justifyContent: "center",
        opacity: 1
    }
}))

const LoadingOverlay = () => {

    const loading = useSelector(selectLoading);

    const classes = useStyles();
    if (loading) {
        return (
            <div className={classes.loader}>
                <div className={classes.spinner}>
                    <FadeLoader color="#F28900" loading={loading} />
                </div>
            </div>
        )
    }

    return null
}

export default LoadingOverlay