import { signInWithEmailAndPassword, signOut } from "firebase/auth"
import { doc, getDoc, getFirestore } from "firebase/firestore";
import { auth } from "../../app/firebase";

const firestore = getFirestore()

export async function firebaseSignOut() {
    try {
        signOut(auth)
        return true
    } catch (error) {
        console.log(error)
        return error
    }
}

export const loginAdmin = async (email, password) => {
    try {
        const resp = await signInWithEmailAndPassword(auth, email, password);
        const { uid } = resp.user;

        const admin = await (await getDoc(doc(firestore, 'admin', uid)));

        if (!admin.exists()) {
            firebaseSignOut();
            new Error();
            return { text: "not admin", error: true }
        } else {
            return { ...admin.data(), uid, error: false }
        }

    } catch (error) {
        console.log(error.message)
        return { text: error.code, error: true }
    }

}

export async function checkUser(uid) {
    try {
        if (uid) {
            const resp = await (await getDoc(doc(firestore, 'admin', uid))).data();
            if (resp) {
                return { ...resp, uid }
            } else {
                firebaseSignOut();
                return false
            }
        } else {
            firebaseSignOut();
            return false
        }
    } catch (error) {
        console.log(error);
        return error
    }

}
