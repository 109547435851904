import { createSlice } from "@reduxjs/toolkit";
import { APP_BAR_ELEMENTS } from "../../app/constants";

export const LAYOUT_SLICE = 'layout';

const initialState = {
    appBarCurrentPage: APP_BAR_ELEMENTS.CONSULTATION,
    unseenMessages: 0,
    pendingCraftsman: 0,
    newConsultations: 0,
    currentActivePage: APP_BAR_ELEMENTS.CONSULTATION
}

export const layoutSlice = createSlice({
    name: LAYOUT_SLICE,
    initialState,
    reducers: {
        setCurrentPage: (state, { payload }) => {
            state.appBarCurrentPage = payload;
        },
        setPendingCraftsman: (state, { payload }) => {
            state.pendingCraftsman = payload;
        },
        setUnseenMessages: (state, { payload }) => {
            state.unseenMessages = payload;
        },
        setNewConsultations: (state, { payload }) => {
            state.newConsultations = payload;
        },
        setCurrentActivePage: (state, { payload }) => {
            state.currentActivePage = payload;
        },
    }
})

export const selectCurrentPage = (state) => state[LAYOUT_SLICE].appBarCurrentPage;

export const selectPendingCraftsman = (state) => state[LAYOUT_SLICE].pendingCraftsman;

export const selectUnseenMessages = (state) => state[LAYOUT_SLICE].unseenMessages;

export const selectNewConsultations = (state) => state[LAYOUT_SLICE].newConsultations;

export const selectCurrentActivePage = (state) => state[LAYOUT_SLICE].currentActivePage;

export const {
    setCurrentPage,
    setPendingCraftsman,
    setUnseenMessages,
    setNewConsultations,
    setCurrentActivePage
} = layoutSlice.actions;

export default layoutSlice.reducer;