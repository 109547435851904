import { format, intervalToDuration } from "date-fns";

export const ROUTES = {
  LOGIN: "/",
  LOGOUT: "/logout",
  CRAFTSMAN_DETAILS: "/craftsman-details/:id",
  CRAFTSMAN_DETAILS_PATH: (id) => "/craftsman-details/" + id,
  USER_DETAILS: "/user-details/:id",
  USER_DETAILS_PATH: (id) => "/user-details/" + id,
  CRAFTSMAN_LIST: '/craftsman-list',
  USERS_LISTING: "/user-list",
  MESSAGES: "/messages",
  CONSULTATIONS_LIST: "/consultations",
  CONSULTATION_DETAILS: "/consultation-details/:id",
  CONSULTATION_DETAILS_PATH: (id) => "/consultation-details/" + id,
};

export const BEARER_TOKEN = "";

export const DATE_FORMAT = "dd/MM/yyyy";
export const DATE_FORMAT_WITH_HOURS = "dd.MM.yyyy hh:mm:ss";

export const ROLES = {
  CLIENT: "client",
  CRAFTSMAN: "craftsman",
  ADMIN: "admin"
};

export const APP_BAR_ELEMENTS = {
  EXPERT: "expert",
  USERS: "users",
  CONSULTATION: "consultations",
  MESSAGES: "messages",
  CALLS: "calls",
  PROFILE: "profile"
};

export const APP_BAR_ACTIONS = {
  BACK: "back",
  NONE: "none",
};

export const FORMAT_HOURS_AND_MINUTES = (date) => format(date, "HH:mm");

export const FORMAT_CONSULTATION_DATE = (date) => format(date, "d MMM yyyy");

export const FORMAT_LAST_MESSAGE_DATE = (date) => format(date, "dd/MM/yy");

export const FORMAT_CALLS_HISTORY_DATE = (date) => format(date, "dd MMM yyyy H:mm ");

export const MESSAGE_TIME = (date) => format(date, "HH:mm");

export const CRAFTSMAN_STATUS = {
  PENDING: "PENDING",
  APPROVED: "APPROVED",
  BLOCKED: "BLOCKED"
}

export const MESSAGE_TYPES = {
  TEXT: "text",
  CALL: "call",
  FILE: "file",
  PHOTOS: "photos",
  DATE_DIVIDER: "divider"
}

export const USER_STATUS = {
  ACTIVE: "ACTIVE",
  BLOCKED: "BLOCKED"
}

export const REQUEST_DURATION = {
  15: "15 min",
  30: "30 min",
  120: "2 hours",
};

export const REQUEST_TYPE = {
  15: "Short (15 min)",
  30: "Short (30 min)",
  120: "Repair (2 hours)",
};

export const REQUESTS_TABS_KEY_WORDS = {
  REQUESTED: ["new", "approved"],
  APPROVED: ["approved"],
  ARCHIVED: ["new", "approved", "denied", "canceled"],
};

export const FORMAT_CONSULTATION_DETAILS_DATE = (date) => format(date, "dd/MM/yyyy");

export const REQUEST_STATES = {
  NEW: "new",
  APPROVED: "approved",
  DENIED: "denied",
  CANCELED: "canceled",
};

export const TODAY = new Date();

export const PAGINATION_LIMIT = 10;

export const GER_RECORDS_FROM_ROOM = (roomHash) => "https://meet.diyassist.pro/api/v2/records/" + roomHash

export const RECORDING_BASE_PATH = "https://video.daskal.eu:444/";

export const FORMAT_DURATION = (time) => {

  const formattedObj = intervalToDuration({ start: 0, end: time * 1000 });

  const { seconds, minutes } = formattedObj;

  const milliseconds = Math.round((time % 1).toFixed(2) * 100).toString()

  const mins = minutes.toString().length < 2 ? "0" + minutes.toString() : minutes.toString();
  const secs = seconds.toString().length < 2 ? "0" + seconds.toString() : seconds.toString();
  const milSecs = milliseconds.length < 2 ? "0" + milliseconds : milliseconds;
  const result = `${mins}:${secs}:${milSecs}`

  return result.substring(0, 8);
} 