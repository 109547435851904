import { getDatabase, ref, onValue, query, limitToLast, set, child, get, push, off } from "firebase/database";
import { getFirestore, doc, getDoc, updateDoc, arrayUnion, arrayRemove, onSnapshot } from "firebase/firestore";
import { getStorage, getDownloadURL, ref as storageRef, uploadString, uploadBytes } from "firebase/storage";
import { v4 as uuidv4 } from 'uuid';
import { ROLES } from "../../app/constants";
import { getAuth } from 'firebase/auth';
import { CreateMessage } from "../../utilities/helpers/messageConstructor";

const firestore = getFirestore();
const dbRef = ref(getDatabase());
const db = getDatabase();
const storage = getStorage();

let listener = null;

const getUserData = async (id) => {
    const craftsman = await (await getDoc(doc(firestore, 'craftsman', id))).data()
    const client = await (await getDoc(doc(firestore, 'client', id))).data()

    if (craftsman) {
        const { profilePictureURL, fullname } = craftsman
        return { profilePictureURL, fullname, role: "craftsman" }
    } else if (client) {
        const { profilePictureURL, fullname } = client
        return { profilePictureURL, fullname, role: "client" }
    } else {
        return false;
    }
}

export const getLastMessage = async function (id) {
    const snapshot = await (await get(child(dbRef, `chats/${id}`))).val()
    if (snapshot) {
        return Object.values(snapshot).pop()
    } else {
        return false
    }
}

export const newMessagesListener = (adminID, callBack) => {

    onSnapshot(doc(firestore, "admin", adminID), callBack)

}

export const getMessagesList = async (IDs = []) => {
    try {
        if (!IDs || !IDs.length) return [];
        const response = IDs.map(async (set) => {
            const isNew = set.includes("@");

            const [userID, messagesID] = set.replace("@", "").replace("_", ",").split(",");
            const userData = await getUserData(userID)
            if (userData) {
                const lastMessage = await getLastMessage(messagesID)
                if (lastMessage) {
                    return {
                        lastMessage,
                        userData,
                        messagesID,
                        userID,
                        isNew
                    }
                } else {
                    return false
                }
            } else {
                return false
            }

        })

        const resolved = await (await Promise.all(response)).filter(Boolean)

        return resolved;

    } catch (error) {
        console.log(error)
    }
}

export const getOtherUser = async (id) => {
    const craftsman = await (await getDoc(doc(firestore, "craftsman", id))).data();
    const client = await (await getDoc(doc(firestore, "client", id))).data();

    if (craftsman) {
        return craftsman;
    } else {
        return client;
    }

}

export const getOtherUserRole = async (id) => {
    const craftsman = await (await getDoc(doc(firestore, "craftsman", id))).exists();

    if (craftsman) {
        return ROLES.CRAFTSMAN;
    } else {
        return ROLES.CLIENT;
    }

}

export const chatMessages = (chatID, cb,) => {
    try {
        if (listener) {
            off(listener)
            listener = null
        }
        const reference = ref(db, '/chats/' + chatID)
        listener = reference;

        const q = query(reference, limitToLast(50));

        return onValue(q, cb, {
            onlyOnce: false
        });

    } catch (error) {
        console.log(error)
    }
}

export const downloadFile = async (path) => {
    return await getDownloadURL(storageRef(storage, `files/${path}`))
        .catch((error) => {
            console.log(error)
        });
}

export const uploadImage = async (image) => {
    const profilePicRef = storageRef(storage, '/images/' + uuidv4());

    return await uploadString(profilePicRef, image, 'data_url')
        .then((snapshot) => {
            return snapshot.ref.fullPath
        });
}

export const uploadFile = async (file) => {
    try {
        const fileRef = storageRef(storage, "/files/" + file.name + "_" + uuidv4());
        return await uploadBytes(fileRef, file)
    } catch (error) {
        console.log(error);
    }
}

export const sendMessage = async (message, chatID,) => {
    try {
        await set(ref(db, `chats/${chatID}/` + message.date), message);
        return true
    } catch (error) {
        console.log(error)
        return false
    }

}

export const getPhoto = async (path) => {
    try {
        return await getDownloadURL(storageRef(storage, path))
    } catch (error) {
        console.log(error)
        return error
    }
}

export const getManyPhotos = async (pathsArr) => {

    const urlArr = pathsArr.map(async (path) => {
        return await getPhoto(path)
    })

    return Promise.all([...urlArr])
}

export const doChatExist = async (userRole, userID) => {
    try {
        const adminID = await getAuth().currentUser.uid

        const adminMessages = await (await getDoc(doc(firestore, "admin", adminID))).data().messages
        const { adminChat, fullname, profilePictureURL } = await (await getDoc(doc(firestore, userRole, userID))).data()
        if (!adminMessages || !adminChat) {
            return false
        } else {
            return {
                messagesID: adminChat,
                userID: userID,
                avatar: profilePictureURL,
                name: fullname
            }
        }

    } catch (error) {
        console.log(error)
    }
}

export const createChatRoom = async (userID, userRole) => {
    try {

        const adminID = await getAuth().currentUser.uid;
        const message = await { ...new CreateMessage('Ask me anything', adminID).msg };

        const msgRef = await push(ref(db, "chats/"));

        const msgID = msgRef.key;

        const adminChatID = `${userID}_${msgID}`;

        const timestamp = message.date;

        await set(msgRef, { [timestamp]: message });

        await updateDoc(doc(firestore, "admin", adminID), {
            messages: arrayUnion(adminChatID)
        })

        await updateDoc(doc(firestore, userRole, userID), {
            adminChat: msgID
        })

        const userData = await (await getDoc(doc(firestore, userRole, userID))).data();

        return {
            messagesID: msgID,
            userID: userID,
            avatar: userData.profilePictureURL,
            name: userData.fullname,
            chatID: adminChatID
        }

    } catch (error) {
        console.log(error)
    }
}

export const seenMessage = async (chatID, otherSideID) => {
    try {
        const chatBody = `${otherSideID}_${chatID}@`;
        const chatBodySeen = `${otherSideID}_${chatID}`;

        const adminID = await getAuth().currentUser.uid;

        const adminData = await (await getDoc(doc(firestore, ROLES.ADMIN, adminID))).data()
        const currentMessages = adminData.messages;

        const currentChatUnseen = currentMessages.some(x => x === chatBody)


        if (currentChatUnseen) {
            await updateDoc(doc(firestore, ROLES.ADMIN, adminID), {
                messages: arrayUnion(chatBodySeen)
            })

            await updateDoc(doc(firestore, ROLES.ADMIN, adminID), {
                messages: arrayRemove(chatBody)
            })
        }

        return chatBodySeen;

    } catch (error) {
        console.log(error);
    }
}

export const setMessageUnseen = async (userID, chatID) => {

    const unseenChat = `${chatID}@`;
    const seenChat = chatID;

    const userRole = await getOtherUserRole(userID);

    const userData = await (await getDoc(doc(firestore, userRole, userID))).data();

    const currentUnseen = userData.adminChat;

    if (seenChat === currentUnseen) {
        await updateDoc(doc(firestore, userRole, userID), {
            adminChat: unseenChat
        })
    }

}