import { createSlice } from '@reduxjs/toolkit';
import { USER_STATUS } from '../../app/constants';

export const USER_LIST_PAGE_SLICE = 'user-list-page';

const initialState = {
    pageView: null,
    category: null,
    requestsTab: USER_STATUS.ACTIVE,
    tabIndex: 0,
    requests: [],
}

export const userListPageSlice = createSlice({
    name: USER_LIST_PAGE_SLICE,
    initialState,
    reducers: {
        setPageView: (state, { payload }) => {
            state.pageView = payload;
        },
        setCategory: (state, { payload }) => {
            state.category = payload;
        },
        setRequestsTab: (state, { payload }) => {
            state.requestsTab = payload;
        },
        setTabIndex: (state, { payload }) => {
            state.tabIndex = payload;
        },
        setRequests: (state, { payload }) => {
            state.requests = payload;
        },
        extendUserRequest: (state, { payload }) => {
            const bounded = [...state.requests, ...payload]
            state.requests = bounded
        }
    }
});

export const selectPageView = (state) => state[USER_LIST_PAGE_SLICE].pageView;

export const selectExpertCategoryName = (state) => state[USER_LIST_PAGE_SLICE].category;

export const selectRequestsTab = (state) => state[USER_LIST_PAGE_SLICE].requestsTab;

export const selectTabIndex = (state) => state[USER_LIST_PAGE_SLICE].tabIndex;

export const selectRequests = (state) => state[USER_LIST_PAGE_SLICE].requests;

export const { setPageView, extendUserRequest, setCategory, setRequestsTab, setTabIndex, setRequests } = userListPageSlice.actions;

export default userListPageSlice.reducer;