import { createSlice } from "@reduxjs/toolkit";

export const CRAFTSMAN_DETAILS = "craftsman-details";

const initialState = {
    craftsmanDetails: {
        languages: [],
        taxPerMinute: 0,
        secondarySpecialities: [],
        email: "",
        fullname: "",
        speciality: "",
        city: "",
        info: "",
        status: ""
    }
}

export const craftsmanDetailsSlice = createSlice({
    name: CRAFTSMAN_DETAILS,
    initialState,
    reducers: {
        setCraftsmanDetails: (state, { payload }) => {
            state.craftsmanDetails = payload;
        }
    }
})

export const selectCraftsmanDetails = (state) => state[CRAFTSMAN_DETAILS].craftsmanDetails;

export const { setCraftsmanDetails } = craftsmanDetailsSlice.actions;

export default craftsmanDetailsSlice.reducer;