import React, { lazy, Suspense } from "react";
import { Switch } from "react-router-dom";
import LoadingLayout from "../features/layout/LoadingLayout";
import { UnauthorizedLayout } from "../features/layout/Unauthorized";

import RouteWithLayout from "../components/routing/routeWithLayout";
import { ROUTES } from "./constants";

import { AuthorizedLayout } from "../features/layout/AuthorizedLayout";
import ProtectedRouteWithLayout from "../components/routing/ProtectedRouteWithLayout";

const SignOut = lazy(() => import("../features/auth/SignOut"));

const LoginPage = lazy(() => import("../features/auth/LoginPage"));
const CraftsmanDetails = lazy(() => import('../features/CraftsmanProfile/CraftsmanProfile'));
const userList = lazy(() => import('../features/UserList/UserList'))
const CraftsmanList = lazy(() => import('../features/CraftsmanList/CraftsmanList'));
const Messages = lazy(() => import('../features/Messages/Messages'));
const UserDetails = lazy(() => import('../features/UserProfile/UserProfile'));
const ConsultationsList = lazy(() => import('../features/ConsultationsList/ConsultationsList'));
const ConsultationDetails = lazy(() => import('../features/ConsultationDetails/ConsultationDetails'));

export function Router() {
  return (
    <Suspense fallback={<LoadingLayout />}>
      <Switch>

        <RouteWithLayout
          layout={UnauthorizedLayout}
          exact={true}
          path={ROUTES.LOGIN}
          component={LoginPage}
        />

        <RouteWithLayout
          layout={UnauthorizedLayout}
          exact={true}
          path={ROUTES.LOGOUT}
          component={SignOut}
        />

        <ProtectedRouteWithLayout
          layout={AuthorizedLayout}
          exact={true}
          path={ROUTES.CRAFTSMAN_DETAILS}
          component={CraftsmanDetails}
        />

        <ProtectedRouteWithLayout
          layout={AuthorizedLayout}
          exact={true}
          path={ROUTES.CRAFTSMAN_LIST}
          component={CraftsmanList}
        />

        <ProtectedRouteWithLayout
          layout={AuthorizedLayout}
          exact={true}
          path={ROUTES.MESSAGES}
          component={Messages}
        />

        <ProtectedRouteWithLayout
          layout={AuthorizedLayout}
          exact={true}
          path={ROUTES.USERS_LISTING}
          component={userList}
        />

        <ProtectedRouteWithLayout
          layout={AuthorizedLayout}
          exact={true}
          path={ROUTES.USER_DETAILS}
          component={UserDetails}
        />

        <ProtectedRouteWithLayout
          layout={AuthorizedLayout}
          exact={true}
          path={ROUTES.CONSULTATIONS_LIST}
          component={ConsultationsList}
        />

        <ProtectedRouteWithLayout
          layout={AuthorizedLayout}
          exact={true}
          path={ROUTES.CONSULTATION_DETAILS}
          component={ConsultationDetails}
        />

      </Switch>
    </Suspense>
  );
}