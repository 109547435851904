import { createSlice } from "@reduxjs/toolkit";

export const CONSULTATION_DETAILS = "consultation-details";

const initialState = {
    requestDate: "",
    requestTime: "",
    duration: "",
    description: "",
    requestAnswerText: "",
    requestState: "",
    photos: [],
    requestAnswerPhotos: [],
    dialogImagesOpen: false,
    dialogAnswerImagesOpen: false,
    craftsman: {},
    client: {},
    rating: 0,
    durationMinutes: 0,
    videoCalls: [],
    selectedRequest: "",
    answerText: "",
    answerPhotos: [],
    dialogAcceptOpen: false,
    recordingOpen: false,
    roomHash: null,
    currentRecords: []

}

const consultationDetailsSlice = createSlice({
    name: CONSULTATION_DETAILS,
    initialState,
    reducers: {
        setRequestDate: (state, { payload }) => {
            state.requestDate = payload;
        },
        setRequestTime: (state, { payload }) => {
            state.requestTime = payload;
        },
        setDuration: (state, { payload }) => {
            state.duration = payload;
        },
        setDescription: (state, { payload }) => {
            state.description = payload;
        },
        setRequestState: (state, { payload }) => {
            state.requestState = payload;
        },
        setPhotos: (state, { payload }) => {
            state.photos = payload;
        },
        setRequestAnswerPhotos: (state, { payload }) => {
            state.requestAnswerPhotos = payload;
        },
        setRequestAnswerText: (state, { payload }) => {
            state.requestAnswerText = payload;
        },
        setDialogImagesOpen: (state, { payload }) => {
            state.dialogImagesOpen = payload;
        },
        setDialogAnswerImagesOpen: (state, { payload }) => {
            state.dialogAnswerImagesOpen = payload;
        },
        setCraftsman: (state, { payload }) => {
            state.craftsman = payload;
        },
        setClient: (state, { payload }) => {
            state.client = payload;
        },
        setRating: (state, { payload }) => {
            state.rating = payload;
        },
        setDurationMinutes: (state, { payload }) => {
            state.durationMinutes = payload;
        },
        setVideoCalls: (state, { payload }) => {
            state.videoCalls = payload;
        },
        setSelectedRequest: (state, { payload }) => {
            state.selectedRequest = payload;
        },
        setAnswerText: (state, { payload }) => {
            state.answerText = payload;
        },
        setAnswerPhotos: (state, { payload }) => {
            state.answerPhotos = [...payload];
        },
        setDialogAcceptOpen: (state, { payload }) => {
            state.dialogAcceptOpen = payload;
        },
        setRecordingOpen: (state, { payload }) => {
            state.recordingOpen = payload;
        },
        setRoomHash: (state, { payload }) => {
            state.roomHash = payload;
        },
        setCurrentRecords: (state, { payload }) => {
            state.currentRecords = payload;
        }
    }
});

export const selectRequestDate = (state) => state[CONSULTATION_DETAILS].requestDate;

export const selectRequestTime = (state) => state[CONSULTATION_DETAILS].requestTime;

export const selectDuration = (state) => state[CONSULTATION_DETAILS].duration;

export const selectDescription = (state) => state[CONSULTATION_DETAILS].description;

export const selectRequestState = (state) => state[CONSULTATION_DETAILS].requestState;

export const selectPhotos = (state) => state[CONSULTATION_DETAILS].photos;

export const selectRequestAnswerPhotos = (state) => state[CONSULTATION_DETAILS].requestAnswerPhotos;

export const selectRequestAnswerText = (state) => state[CONSULTATION_DETAILS].requestAnswerText;

export const selectOpenImagesDialog = (state) => state[CONSULTATION_DETAILS].dialogImagesOpen;

export const selectDialogAnswerImages = (state) => state[CONSULTATION_DETAILS].dialogAnswerImagesOpen;

export const selectCraftsman = (state) => state[CONSULTATION_DETAILS].craftsman;

export const selectClient = (state) => state[CONSULTATION_DETAILS].client;

export const selectRating = (state) => state[CONSULTATION_DETAILS].rating;

export const selectDurationMinutes = (state) => state[CONSULTATION_DETAILS].durationMinutes;

export const selectVideoCalls = (state) => state[CONSULTATION_DETAILS].videoCalls;

export const selectSelectedRequest = (state) => state[CONSULTATION_DETAILS].selectedRequest;

export const selectAnswerText = (state) => state[CONSULTATION_DETAILS].answerText;

export const selectAnswerPhotos = (state) => state[CONSULTATION_DETAILS].answerPhotos;

export const selectDialogAcceptOpen = (state) => state[CONSULTATION_DETAILS].dialogAcceptOpen;

export const selectRecordingOpen = (state) => state[CONSULTATION_DETAILS].recordingOpen;

export const selectRoomHash = (state) => state[CONSULTATION_DETAILS].roomHash;

export const selectCurrentRecords = (state) => state[CONSULTATION_DETAILS].currentRecords;

export const {
    setRequestDate,
    setRequestTime,
    setDuration,
    setDescription,
    setRequestState,
    setPhotos,
    setRequestAnswerPhotos,
    setRequestAnswerText,
    setDialogImagesOpen,
    setDialogAnswerImagesOpen,
    setClient,
    setCraftsman,
    setRating,
    setDurationMinutes,
    setVideoCalls,
    setSelectedRequest,
    setAnswerPhotos,
    setAnswerText,
    setDialogAcceptOpen,
    setRecordingOpen,
    setRoomHash,
    setCurrentRecords
} = consultationDetailsSlice.actions;

export default consultationDetailsSlice.reducer;