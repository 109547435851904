import { createSlice } from "@reduxjs/toolkit";

export const AUTH_SLICE = 'auth';

const initialState = {
    email: null,
    role: null,
    uid: null,
    messages: []
};

export const authSlice = createSlice({
    name: AUTH_SLICE,
    initialState,
    reducers: {
        authenticate(state, { payload }) {
            state.email = payload.email
            state.role = payload.role
            state.uid = payload.uid
            state.messages = payload.messages;
        },
        signOut(state) {
            state.email = null
            state.role = null
            state.uid = null
            state.messages = [];
        },
        setNewChat: (state, { payload }) => {
            const bounded = [...state.messages, ...payload];
            state.messages = bounded;
        },
        setChatSeen: (state, { payload }) => {
            const temp = [...state.messages];

            if (temp.includes(payload + "@")) {
                var index = temp.indexOf(payload + "@");

                if (index !== -1) {
                    temp[index] = payload;
                }
            }

            state.messages = temp;
        }
    },
});

export const selectUid = (state) => state[AUTH_SLICE].uid;

export const selectEmail = (state) => state[AUTH_SLICE].email;

export const selectRole = (state) => state[AUTH_SLICE].role;

export const selectMessages = (state) => state[AUTH_SLICE].messages;

export const { authenticate, signOut, setNewChat, setChatSeen } = authSlice.actions;

export default authSlice.reducer