import { MESSAGE_TIME } from "../../app/constants";


export const CreateMessage = class {
    constructor(
        message = "",
        from = "",
        fileName = "",
        filePath = "",
        fileSize = "",
        videoCallID = "",
        imagePaths = [],
        imageURLs = [],
    ) {
        this.message = message
        this.from = from;
        this.fileName = fileName;
        this.filePath = filePath;
        this.fileSize = fileSize;
        this.videoCallID = videoCallID;
        if (imagePaths.length) this.imagePaths = imagePaths;
        if (imagePaths.length) this.imageURLs = imageURLs;

    }

    setDateAndTime() {
        const d = new Date();
        this.date = d.getTime();
        this.time = MESSAGE_TIME(d)
    }

    get msg() {
        this.setDateAndTime();
        return this
    }


}