import React from 'react'
import { makeStyles } from '@material-ui/core'
import DatabaseListeners from './DatabaseListeners';

const useStyles = makeStyles((theme) => ({
    root: {
        [theme.breakpoints.up("md")]: {
            margin: "auto",
            backgroundColor: theme.palette.common.white,
            minWidth: 960,
            maxWidth: 1400,
            marginTop: 80,
            minHeight: "80vh",
            marginBottom: 20,
        },
    }
}));


const MainLayout = ({ children }) => {
    const classes = useStyles();

    return (
        <DatabaseListeners>
            <div className={classes.root}>
                <div className={classes.inner}>
                    {children}
                </div>
            </div>
        </DatabaseListeners>
    )
}

export default MainLayout